import * as React from "react"
import Layout from "../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import ImageLinkRow from "../../components/ImageLinkRow"

const IndexPage = () => {
    const root = "/img/download/leaders/print"

    return (
        <Layout pageTitle="Leaders - Printed Assets">
            <Row>
                <Col>
                    <Link to="/leaders">Back</Link>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={6}></Col>
            </Row>
            <ImageLinkRow
                name="Business Cards"
                text={`This is a Canva template to create business cards.`}
                image={`${root}/business-card.png`}
                url="https://www.canva.com/design/DAEoGy0I84o/Gx9Ok5Bb3UD2CSPWQUrnXw/view?utm_content=DAEoGy0I84o&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            />
            <Row className="mt-5">
                <Col md="6">
                    <img
                        src={`${root}/rose-tag.png`}
                        className="img-fluid"
                        alt=""
                        style={{ maxHeight: "350px" }}
                    />
                </Col>
                <Col>
                    <h3>Rose Tags</h3>
                    <p className="para" style={{ whiteSpace: "pre-wrap" }}>
                        Print these to attach to roses to give out at events.
                        You can print them as business cards.
                    </p>
                    <a
                        href={`${root}/rose-tag-side-1.png`}
                        download={`rose-tag-side-1.png`}
                        className="btn btn-primary m-3"
                    >
                        Download side 1
                    </a>
                    <a
                        href={`${root}/rose-tag-side-2.png`}
                        download={`rose-tag-side-2.png`}
                        className="btn btn-primary m-3"
                    >
                        Download side 2
                    </a>
                </Col>
            </Row>
            <hr className="mt-5" />
            <Row className="mt-5">
                <h2>Roller Banners</h2>
            </Row>
            <Row className="mt-5">
                <Col>
                    <p className="para" style={{ whiteSpace: "pre-wrap" }}>
                        You can use these designs to order roller banners for
                        your club and events (such as exhibiting at business
                        shows).
                    </p>
                    <p className="para" style={{ whiteSpace: "pre-wrap" }}>
                     Arrange your own printing <a href="https://instantprint.co.uk/largeformat/rollerbanners" target="_blank">here</a> or <a href="https://news.womensbusiness.club/product/pop-up-roller-banner/" target="_blank">order with us</a> to take care of it for you.
                    </p>
                    <h4 className="mt-5">Coffee &amp; Coworking Roller Banner</h4>
                    <p className="para">
                        Display banner for Coffee & Coworking events. Essential to make your presence known and official in a venue.
                    </p>
                    <a
                        href={`${root}/coffee-coworking-banner.pdf`}
                        download={`coffee-coworking-banner.pdf`}
                        className="btn btn-primary m-3"
                    >
                        Download Coffee &amp; Coworking Banner
                    </a>
                    <h4 className="mt-5">Twin Roller Banners</h4>
                    <p className="para">
                        These roller banners work well together. If you need to
                        choose one, choose design two as a stand alone.
                    </p>
                    {/* <a
                        href={`${root}/roller-banner-one.pdf`}
                        className="btn btn-primary m-3"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        Download
                    </a> */}
                    <a
                        href={`${root}/roller-banner-one.pdf`}
                        download={`roller-banner-one.pdf`}
                        className="btn btn-primary m-3"
                    >
                        Download Roller Banner One
                    </a>
                    <a
                        href={`${root}/roller-banner-two.pdf`}
                        download={`roller-banner-two.pdf`}
                        className="btn btn-primary m-3"
                    >
                        Download Roller Banner Two
                    </a>
                    
                </Col>
            </Row>
            <hr className="mt-5" />
            <Row className="mt-5">
                <h2>Brochures &amp; Leaflets</h2>
            </Row>
                <ImageLinkRow
                name="Coffee &amp; Coworking Leaflet"
                text={`This is a Canva template to create your own digital or print leaflets. Add your event's details, including the number at the end of your unique Eventbrite link.  Only add the number. You can print these at https://www.instantprint.co.uk/flyers-leaflets/a6 with the following specifications: A6 105mm x 148mm Double Sided 250gsm Silk Velvet`}
                image={`${root}/coffee-coworking-flyer.png`}
                url="https://www.canva.com/design/DAFXc0myMqU/h6DubvkytCrPqbgKsUqSAA/view?utm_content=DAFXc0myMqU&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            />
        </Layout>
    )
}

export default IndexPage
